import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
	<Layout>
		<SEO title="Welcome" />
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 146 36"
			height="72"
			aria-hidden="true"
			focusable="false"
			role="img">
			<path
				d="M140.749 26.512A4.253 4.253 0 0 1 145 30.763a4.253 4.253 0 0 1-4.251 4.251 4.253 4.253 0 0 1-4.251-4.251 4.253 4.253 0 0 1 4.251-4.251zM24.05.997v1.227C22.354 1.426 20.199.987 17.934.987 8.504.987 1 8.6 1 17.99s7.434 17.003 16.854 17.003c2.265 0 4.49-.439 6.186-1.237v1.257h8.502V.997H24.05zm0 22.601c-1.277 1.776-3.572 2.894-6.116 2.894-4.71 0-8.392-3.812-8.392-8.502 0-4.69 3.622-8.502 8.322-8.502 2.554 0 4.909 1.128 6.186 2.894v11.216zM92.074.997v18.071c0 4.101-3.343 7.444-7.444 7.444s-7.444-3.343-7.444-7.444V.997h-8.502v18.071c0 8.801 7.135 15.945 15.946 15.945s15.946-7.134 15.946-15.945V.997h-8.502zm32.45 22.771c4.62-1.567 7.943-5.928 7.943-11.077 0-6.456-5.239-11.694-11.695-11.694h-15.946v34.016h8.502V22.261l10.637 12.752h9.769l-9.21-11.245zm-3.752-7.883h-7.444V9.508h7.444a3.212 3.212 0 0 1 3.193 3.193v.003a3.196 3.196 0 0 1-3.181 3.181h-.012zM66.569.997H36.803v8.501h14.219L34.677 35.013h29.766v-8.501H50.224L66.569.997z"
				fill="#fff"
			/>
		</svg>
	</Layout>
);

export default IndexPage;
